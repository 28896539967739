@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}

.register_mobile{
    margin-top: 20px;
    padding-left: 35px;

}

.label_mobile{
    display: flex;
    background: #633AF7;
    border-radius: 20px;
    width:50%;
    padding-top: 9px;
    padding-left: 8px;
    height: 36px;
    margin-bottom: 19px;

}

.label_mobile > p{

    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 1.2;
    line-height: 20px;
    /* or 133% */



    color: #FFFFFF;

}

.input_mobile_register{

    font-family: PoppinsRegular;
    border:none;
    width: 80%;
    height: 50px;
    border: 1px solid black;
    box-sizing: border-box;
    border-radius: 70px;
    padding-left: 0;
    margin-top: 5px;
    font-size: 1.0em;


}

.button_register_mobile{

    display: flex;
    width: 90%;
    justify-content: center;

}

.flex_inputs_register_mobile_step2{

    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;

}

.terms_and_conditions_mobile > h2{

    font-family: PoppinsRegular;
    font-size:0.9em;
    font-weight: bold;

}

.message_error_mobile_step2{

    color:red;
    font-size: 0.9em;

}
.message_error_mobile_step2_sex{

    position: relative;
    top:-15px;
    color:red;
    margin-left:5px;
    font-size: 0.9em;

}