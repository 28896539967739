.input_register_mobile{
    font-family: PoppinsRegular;
    border:none;
    width: 80%;
    height: 50px;
    border: 1px solid black;
    box-sizing: border-box;
    border-radius: 70px;
    padding-left: 0;
    margin-top: 5px;
    font-size: 1.0em;
}

