@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}
html,body{

    height: 100%;

}



.plans_mobile_container{

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
}

.plans_mobile_container > h2{
    
    font-family: PoppinsRegular;

    font-weight: 900;
    font-size: 1.4em;
    line-height: 135.2%;
    /* or 27px */

    text-align: center;

    color: #633AF7;


}