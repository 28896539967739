
/* Fonts */


@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}




input{
    font-family: PoppinsRegular;
    width: 80%;
    padding:20px;
    height: 59px;
    border:none;
    outline: none;
    font-size: 1.0em;
    border: 1px solid rgba(0, 0, 0, 0.51);
    text-indent: 15px;
    border-radius: 70px;
    color:black;
    margin-bottom: 20px;
}