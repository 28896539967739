
@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}
.icon_sucess_page_mobile{

    display: flex;
    justify-content: center;

}

.icon_sucess_page_mobile > img{

    width: 180px;

}

.sucess_message_page_mobile{

    margin-top: 15px;
    padding-left: 30px;

}

.sucess_message_page_mobile > h2{

    font-family: PoppinsRegular;
    font-weight: bold;
    color:#633AF7;
    
}

.sucess_message_page_mobile > p{

    font-family: PoppinsRegular;
    font-style: normal;
    font-size: 15px;
    line-height: 20px;
    margin-top: 9px;
}

.icon_handle_succes_page_mobile{

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.icon_handle_succes_page_mobile > img{

    width: 190px;

}