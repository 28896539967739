
@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}
.dashboard_mobile{

    display: flex;


}

.row_dashboard_mobile{

    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}   

.row_dashboard_mobile > div{


    background: #633AF7;
    border-radius: 16px;
    width: 48%;
    height: 91px;

    font-family: PoppinsRegular;
    padding-left:12px;
    padding-top: 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 0.8em;
    /* identical to box height, or 20px */


    color: #FFFFFF;

}

.flex_row_dashboard_indications_mobile{

    display: flex;
    align-items: center;
    

}

.flex_row_dashboard_indications_mobile > .punhos_icon_indications_mobile{

    width: 45px;
    margin-top: -12px;
    margin-left: -10px;

}

.porco_icon_indications_mobile{

    width: 45px;
    margin-top: -12px;
    margin-left: -15px;

}

.arrow_img_value_mobile{

    position: relative;
    top:-5px;
    left:6px;
    width: 25px;

}

.flex_row_dashboard_indications_mobile{

    margin-top: -5px;

}

.flex_row_dashboard_indications_mobile > p{

    position: relative;
    top:5px;

}

.arrow_img_indications_mobile{
    position: relative;
    top:-5px;
    left:6px;
    width: 25px;

}

.link_indications_dashboards_mobile{
    display: block;
    background: #633AF7;
    border-radius: 16px;
    width: 100%;
    height: 91px;
    margin-bottom: 120px;
    margin-top: 10px;

    font-family: PoppinsRegular;
    padding-left:12px;
    padding-top: 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 0.8em;
    /* identical to box height, or 20px */


    color: #FFFFFF;
}

.row_container_link_indications_mobile{

    display: flex;
    justify-content: space-around;
    width:80%;

}

.flex_text_dashboard_mobile{

    display:flex;
    width: 93%;

}

.flex_text_dashboard_mobile > h2{

        font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 1.2em;
    /* identical to box height, or 26px */


    color: #633AF7;

}

.copy_link_dashboard_indications_mobile{

    background: none;
    display: flex;
    border:none;
    color:white;
    font-weight: bold;
}

.share_dashboard_indications_mobile{

    background: none;
    display: flex;
    border:none;
    color:white;
    font-weight: bold;
}
