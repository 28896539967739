

@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}

.historic_transactions_dashboard_mobile > h2{

    font-style: normal;
    font-weight: 900;
    font-size: 1.5em;
    margin-top:20px;
    line-height: 135.2%;
    /* or 27px */

    text-align: center;

    color: #633AF7;

    font-family: PoppinsRegular;

}



.container_dashboards_historic_transactions_mobile{

    width: 80%;
    margin:20px auto;


}

.data_historic_transactions{

    position: relative;
    top:-90px;

}

.data_historic_transactions > p{

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */


    /* black */

    color: #080422;

    opacity: 0.5;

}