

@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}



.container_partners{

    display: flex;

}

.row_container{
    display: flex;
}



.column3_cards_partners{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin:0 auto;
}

.container_cards_partners{
    margin-top: 120px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    width:100%;
    justify-content: center;
    padding-left: 200px;
}

.address_partner{

    margin-top: 50px;

}

.container_partners_mobile > div{

    display: none;

}


.container_partners_mobile__2 > div {

    display: none;

}

.container_partners_mobile__2 > h2 {

    display: none;

}



@media screen and (max-width:720px){

    .row_container > div {

        display:none;
    
    }

    .container_partners{

        display: none;
    
    }
    
    .row_container{
        display: none;
    }


    .column3_cards_partners{
        display: none;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        margin:0 auto;
    }
    
    .container_cards_partners{
        margin-top: 120px;
        align-items: center;
        display: none;
        flex-wrap: wrap;
        width:100%;
        justify-content: center;
        padding-left: 200px;
    }
    

    .container_partners{

        display: none;

    }
    


    .container_partners_mobile__2 > div {

        display: flex;
    
    }
    
    .container_partners_mobile__2 > h2 {
    
        display: block;
        text-align: center;
        margin-top: 15px;
        font-size: 1.3em;
        color:#633AF7;
        font-family: PoppinsRegular;

    }
    
    


}