@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}

.button_mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: PoppinsRegular;
    font-weight: bold;
    font-size: 19px;
    color:white;
    border:none;
    outline: none;
    width: 80%;
    height: 48px;
    background: #633AF7;
    border-radius: 30px;
    margin-top: 30px;
}

.button_mobile_container_buttons{

    display: flex;
    align-items: center;

}

.button_mobile_container_buttons > img{

    width: 20px;
    position: relative;
    left:-8px;

}