

@font-face {
    font-family: Poppins-Regular;
    src: url(../../fonts/Poppins-Regular.ttf);
}

.header{
    position: fixed;
    top:0;
    z-index: 6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    
    background: white;
    width: 100%;
}

.header > h1{
    color:#633AF7;
    font-family: Poppins-Regular;
    margin-left: 260px;
    font-weight: 600;
    font-size: 30px;
}

#dropdownMenuButton{
    display: flex;
    align-items: center;
    margin-right: 50px;
    background: none;
    background: #FFFFFF;
    border: 1px solid #C1C1C1;
    border-radius: 60px;
    height: 60px;
    color:black;
    
}

#dropdownMenuButton > p{

    position: relative;
    top:5px;

}

.icon_partner_dropdown{
    position: relative;
    left:-10px;
    object-fit: contain;
    width: 50px;
    height: 50px;
    border-radius: 100em;

}

