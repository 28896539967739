
.register_mobile{

    display: none;

}

.header_mobile{

    display: none;
}

/* Fonts */


@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}

html,body{

    height: 100%;

}

.container_fluid{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.column1_landing{
    display: flex;
    position: fixed;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 68, 248, 0.32) 0%, rgba(102, 49, 255, 0.24) 100%);
    width: 40%;
    height: 100fr;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.clean_right_desktop{

    width: 50%;
     
}


.column1_landing > .icon_card{

    width: 380px;
    position: relative;
    top:10px;
}

.terms_privacity {
    margin-top: -20px;
    display: flex;
    
}

.terms_privacity > a{

    font-family: PoppinsRegular;
    color:#4120b8;
    margin-left: 5px;
}

.column1_landing> .textPrimary{
    font-family: PoppinsRegular;
    font-weight: bold;
    font-size: 2.0em;
    line-height: 45px;
    text-align: center;
    position: relative;
    top:-75px;
    letter-spacing: -0.03em;
    color: #633AF7;
}

.column1_landing > .textSecondary{
    font-family: PoppinsRegular;
    font-weight: bold;
    font-size: 2.0em;
    line-height: 45px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #633AF7;
    position: relative;
    top:-75px;

}

.column1_landing > .textDescription{
    margin-top: 15px;
    font-family: PoppinsRegular;
    font-size: 16px;
    line-height: 30px;
    /* or 181% */
    position: relative;
    top:-85px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
}

.column1_landing > .info{
    font-family: PoppinsRegular;
    font-size: 1.1em;
    color:#633AF7;
    text-decoration: none;
    position: relative;
    top:-106px;

}

.column1_landing > .buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    position: relative;
    top:-110px;

}

.select_desktop_landing{

    height: 59px;
    width: 80%;
}

.column2{

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    padding-top: 30px;
    height: 100vh;
}

.column2 > .container_inputs{

    display: flex;
    width: 90%;
    position: relative;
    align-items: center;
    margin-bottom: 50px;

    justify-content: space-around;
}

.flex_column_data_acess{

    display: flex;
    flex-direction: column;
    margin-top: 15px;

}

.container_inputs2{

    display: flex;
    align-items: center;
    background-color: red;
}

.data_access{

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 320px;
}

.descriptionName{
    font-family: PoppinsRegular;
    font-weight: bold;
    font-size: 0.8em;
    display: flex;
    padding-left: 10px;
    padding-top: 4px;
    color:white;
    width: 40%;
    height: 30px;
    background: #633AF7;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 20px;

}

.descriptionName_people_data{

    position: relative;
    font-family: PoppinsRegular;
    font-weight: bold;
    font-size: 0.8em;
    display: flex;
    padding-left: 10px;
    padding-top: 4px;
    color:white;
    width: 40%;
    height: 30px;
    background: #633AF7;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 20px;
}

.descriptionName_address{

    font-family: PoppinsRegular;
    font-weight: bold;
    font-size: 0.8em;
    display: flex;
    padding-left: 10px;
    padding-top: 4px;
    color:white;
    width: 40%;
    height: 30px;
    background: #633AF7;
    margin-top: 0px;
    position: relative;
    top:-15px;
    border-radius: 20px;

}

.column2 > img{
    position: relative;
    width: 200px;

}


.column2 > .container_inputs > .data_access > .textCad{
    font-family: PoppinsRegular;
    font-weight: bold;
    color:#633AF7;
    font-size: 1.8em;
}


.column_flex_desktop{

    display: flex;   
}

.flex_column_container{

    display: flex;
    
}

.select{
    background: none;
    font-family: PoppinsRegular;
    width: 70%;
    height: 59px;
    border:none;
    outline: none;
    font-size: 1.3em;
    border: 1px solid rgba(0, 0, 0, 0.51);
    text-indent: 15px;
    border-radius: 70px;
    color: rgba(0, 0, 0, 0.65);    
    margin-bottom: 20px;
}


.textCad2{

    color:white;

}

.flex{

    display: flex;
    justify-content: space-around;
}

.flex_checkbox{
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.flex_checkbox > label{

    font-size: 0.8em;
    position: relative;
    top:-15px;
    left:2px;   

}

.indication_name_desktop{

    background: #D5C9FF;
    border-radius: 30px;
    width: 80%;
    position: relative;
    top:5px;
    display: flex;
}

.indication_name_desktop > p{

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9em;
    line-height: 22px;
    display: flex;
    align-items: center;
    position: relative;
    top:5px;
    left:10px;
    color: #633AF7;
}

.flex_checkbox > input[type="checkbox"]{
    appearance: none;
    transform: scale(0.5);
    width: 20px;
    height: 20px;
    border: 2px solid #633AF7;
    margin-top: -20px;
    border-radius: 10px;
    

}

.flex_checkbox > input[type="checkbox"]:checked{

    background-color: #633af7;

}



.terms{

    margin-top: 20px;

}

.terms > .text_primary{

    font-family: PoppinsRegular;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 800;

}


.text_checkbox{
    font-family: PoppinsRegular;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 800;
    margin-left: 5px;
    font-size: 0.9em;
}

.button_cad{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

}

.flex > input[type="text"]{

    width: 30%;

}

.container_erros{

    position: relative;
    margin-top: 15px;

}

.errorMessage{
    position: absolute;
    left:20px;
    top:60px;
    color:red;
    
}

.error_message_checkbox{
    position: relative;
    left:20px;
    font-size: 12px;
    top:-20px;
    color:red;

}

@media screen and (max-width:720px){

    .container_fluid{

        display: none;

    }

    .register_mobile > select{

        font-family: PoppinsRegular;
        border:none;
        width: 90%;
        height: 50px;
        border: 1px solid black;
        box-sizing: border-box;
        border-radius: 70px;
        padding-left: 0;
        margin-top: 5px;
        font-size: 1.0em;
    }

    .select_mobile > select{
        font-family: PoppinsRegular;
        border:none;
        width: 90%;
        height: 50px;
        border: 1px solid black;
        box-sizing: border-box;
        border-radius: 70px;
        padding-left: 0;
        margin-top: 5px;
        font-size: 1.0em;
    }


    .register_mobile{
        display: block;
        margin-top: 20px;
        padding-left: 35px;
    
    }
    
    .label_mobile{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        background: #633AF7;
        border-radius: 20px;
        width:50%;
        padding-top: 9px;
        padding-left: 8px;    
        height: 36px;
        margin-bottom: 19px;
        font-family: PoppinsRegular;

    }
    
    .label_mobile > p{
    
        font-family: PoppinsRegular;
        font-style: normal;
        font-weight: bold;
        font-size: 1.2;
        line-height: 20px;
        /* or 133% */
    
    
        color: #FFFFFF;
    
    }
    
    .input_mobile_register{
    
        font-family: PoppinsRegular;
        border:none;
        width: 80%;
        height: 50px;
        border: 1px solid black;
        box-sizing: border-box;
        border-radius: 70px;
        padding-left: 0;
        margin-top: 5px;
        font-size: 1.0em;
    
    
    }
    
    .button_register_mobile{
    
        display: flex;
        width: 90%;
        height: 120px;
        justify-content: center;
    
    }

    .errorMessageMobile{

        color:red;
        position: relative;
        top:-15px;
        margin-left: 10px;
        height: 0;

    }

    

}