
@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}


.header_profile_mobile{

    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    padding-top: 50px;

}
.header_profile_mobile > img{

    width: 40px;
    position: relative;
    left:-50px;

}

.header_profile_mobile > h2{

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    position: relative;
    top:5px;
    line-height: 20px;
    /* identical to box height, or 100% */

    text-align: center;

    color: #633AF7;

}

.container_inputs_profile_mobile{

    padding: 58px;
    

}

.container_inputs_profile_mobile > p{

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 25px;
    /* identical to box height, or 140% */
    display: flex;
    align-items: center;

    color: #633AF7;

}

.container_profile_user_mobile{

    display: flex;
    justify-content: center;
    margin-bottom: 20px;

}

.user_profile_mobile{

    position: relative;
    width: 120px;

}

.camera_icon_profile_mobile{

    position: absolute;
    width: 35px;
    top:229px;
    left:210px;

}


.input_column_profile_mobile > input{

    width: 100%;
    background: rgba(216, 219, 221, 0.4);
    border:none;
    outline:none;
    text-indent: 45px;
}


.input_profile_mobile2{

    background: red;

}


.input_profile_mobile{

    position: relative;

}

.icon_input_profile_mobile{

    position: absolute;
    margin-top: 15px;
    left:64px;
    width:30px;

}

.select_bank_option_profile_mobile{
    display: flex;
    align-items: center;
    background: rgba(216, 219, 221, 0.4);
    height: 63px;
    width: 100%;
    border-radius: 60px;

}

.option_1_profile_mobile > img{

    width: 45px;

}

.option_1_profile_mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    width: 50%;
    height: 63px;
    background: #633AF7;
    border-radius: 60px;
}

.option_2_profile_mobile_not_Select{

    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    width: 50%;
    height: 63px;
    border-radius: 60px;
}

.option_2_profile_mobile_not_Select > p{

    color:#633AF7;
    position: relative;
    top:8px;

}

.option_2_profile_mobile_not_Select > img{

    width:45px;

}

.option_1_profile_mobile > p{
    font-family: PoppinsRegular;

    position: relative;
    top:9px;

}

.option_2_profile_mobile{

    display: flex;
    justify-content: center;
    width: 50%;
    height: 63px;
    align-items: center;

}

.option_2_profile_mobile > img{

    width: 60px;
}

.select_option_1_profile_mobile > select{

    margin-top: 25px;
    width: 100%;
    background: rgba(216, 219, 221, 0.4);
    border:none;
    outline:none;
}


.select_option_1_profile_mobile > input{

    margin-top: 25px;
    width: 100%;
    background: rgba(216, 219, 221, 0.4);
    border:none;
    outline:none;
}


.input_pix_profile_mobile > input{

    margin-top: 25px;
    width: 100%;
    background: rgba(216, 219, 221, 0.4);
    border:none;
    outline:none;

}

.flex_select_option_1_profile_mobile{

    display: flex;
    width:100%;
    justify-content: space-between;

}

.flex_select_option_1_profile_mobile > input{

    width: 100%;
    background: rgba(216, 219, 221, 0.4);
    border:none;
    outline:none;
    margin-left: 5px;
}

.buttons_profile_mobile{

    display: flex;
    flex-direction: column;

}

.save_data_profile_mobile{
    font-family: PoppinsRegular;

    margin-top: 10px;
    margin-bottom: 9px;
    background: #633AF7;
    border-radius: 70px;
    border:none;
    outline:none;
    height: 56px;
    color:white;
}

.exit_profile_mobile{
    font-family: PoppinsRegular;

    border-radius: 70px;
    border:none;
    outline:none;
    height: 56px;
    background: none;
    color:#FB4E4E;

}

.error_message_profile_mobile{

    color:red;
    font-size: 0.9em;
    position: relative;
    left:5px;
    top:-9px;
}

.user_profile_mobile{

    border-radius: 100px;

}