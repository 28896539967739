
.mobile_layout{

    display: none;

}

/* Fonts */


@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}

@font-face{

    src:url(../../fonts/Manrope-ExtraBold.ttf);
    font-family: Manrope-Regular;

}

html,body{

    height: 100%;

}

.container_fluid{
    display: flex;
    justify-content: space-between;    
    width: 100%;
}

.column1{
    
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 68, 248, 0.32) 0%, rgba(102, 49, 255, 0.24) 100%);
    width: 40%;
    padding-top: 30px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.column1 > .icon_card{

    width: 380px;
    position: relative;
    top:40px;
}

.column1 > .textPrimary{
    font-family: PoppinsRegular;
    font-weight: bold;
    font-size: 2.2em;
    line-height: 45px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #633AF7;
}

.column1 > .textSecondary{
    font-family: PoppinsRegular;
    font-weight: bold;
    font-size: 2.2em;
    line-height: 45px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #633AF7;
}

.column1 > .textDescription{
    margin-top: 15px;
    font-family: PoppinsRegular;
    font-size: 16px;
    line-height: 30px;
    /* or 181% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
}

.column1 > .info{
    font-family: PoppinsRegular;
    font-size: 1.1em;
    color:#633AF7;
    text-decoration: none;

}

.column1 > .buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;

}

.column2{

    display: flex;
    flex-direction: column;
    height: 100vh;   
    width: 70%;
}

.footer_version{
    font-family: PoppinsRegular;
    margin-top: 80px;
}

.footer_version > h2{

    font-size: 17px;

}

.column2 > .container_inputs_logIn{
    width: 55%;
    display: flex;
    flex-direction: column;

}

.container_inputs_logIn > input{

    width: 40%;

}

.icon_container_inputs_login{

    width: 170px;

}

.container_inputs_logIn > h2{
    font-family: PoppinsRegular;
    font-weight: 700;
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    letter-spacing: 0.03em;

    color: #633AF7;


}

.container_inputs_logIn > .row_inputs_login > a{

    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    
    text-align: center;
    
    color: #633AF7;
    margin-left: 20px;
    
}

.data_access{

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 320px;
}

.descriptionName{
    font-family: PoppinsRegular;
    font-weight: bold;
    font-size: 0.8em;
    display: flex;
    padding-left: 10px;
    padding-top: 4px;
    color:white;
    width: 40%;
    height: 30px;
    background: #633AF7;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 20px;

}

.column2 > img{
    position: relative;
    width: 200px;

}

.column2 > .container_inputs > .data_access > .textCad{
    font-family: PoppinsRegular;
    font-weight: bold;
    color:#633AF7;
    font-size: 1.8em;
}


.select{
    background: none;
    font-family: PoppinsRegular;
    width: 70%;
    height: 59px;
    border:none;
    outline: none;
    font-size: 1.3em;
    border: 1px solid rgba(0, 0, 0, 0.51);
    text-indent: 15px;
    border-radius: 70px;
    color: rgba(0, 0, 0, 0.65);    
    margin-bottom: 20px;
}


.textCad2{

    color:white;

}

.flex{

    display: flex;
    justify-content: space-around;
}

.flex_checkbox{
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.flex_checkbox > input[type="checkbox"]{
    width: 20px;
    height: 20px;
    border: 2px solid #633AF7;
}

.terms{

    margin-top: 20px;

}

.terms > p{

    font-family: PoppinsRegular;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 800;

}


.text_checkbox{
    font-family: PoppinsRegular;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 800;
    margin-left: 5px;
    font-size: 0.9em;
}

.button_cad{
    margin-top: 50px;
    display: flex;
    justify-content: center;

}

.flex > input[type="text"]{

    width: 30%;

}

.container_erros{

    position: relative;
    margin-top: 5px;

}

.errorMessage{
    position: absolute;
    left:20px;
    top:60px;
    color:red;
    
}

.row_inputs_login{

    display: flex;
    align-items: center;
    margin-top: 25px;
}

.row_inputs_login > a{
    font-family: PoppinsRegular;

    font-size: 14px;
    /* identical to box height, or 143% */

    text-align: center;

    color: #633AF7;

}

.messageError{

    color:red

}

.arrow_container_desktop{

    margin-top: 10px;
    width: 500px;
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.arrow_container_desktop > p{
    
    font-size: 0.9em;
    position: relative;
    top:8px;
    font-weight: 500;

}

.facebook_button_desktop{
    width: 320px;
    margin-top: 30px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 70px;
    height: 50px;

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;

    color: #000000;


}

.container_button_facebook_desktop{

    position: relative;
    display: flex;
    align-items: center;
    width: 320px;
}

.container_button_facebook_desktop > img{

    position: absolute;
    left:30px;
    top:40px;
    width: 30px;

}


@media screen and (max-width:720px){

    .container_fluid{

        display: none;

    }

    .mobile_layout{

        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100fr;
        width:100%;
        background: linear-gradient(347.37deg, #FFBDFD 28.18%, #C6B6FF 94.04%);

    }

    .mobile_layout_landing_app{

        display: flex;
        flex-direction: column;
        align-items: center;

    }


    .mobile_layout_landing_app > .icon_mobile_layout_landing_app{
        display: flex;
        flex-direction: column;
        width: 200px;

    }

    .mobile_layout_landing_app > .card_icon_mobile_layout_landing_app{


        width:360px;
        margin-top: -70px;
    }

    .description_mobile_layout_landing_app > h2{

        font-family: Manrope-Regular;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 41px;
        text-align: center;
        letter-spacing: -0.03em;
        color: #633AF7;


    }

    .description_mobile_layout_landing_app > .description_app_mobile_layout_landing_app{

        font-family: PoppinsRegular;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        /* or 125% */
        margin-top: 8px;
        align-items: center;
        text-align: center;

        color: #000000;

    }

    .description_mobile_layout_landing_app{

        margin-top: -60px;

    }

    .saiba_mais_mobile_layout_landing_app{

        font-family: PoppinsRegular;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        /* or 118% */

        text-align: center;

        color: #633AF7;

    }

    .container_buttons_mobile_layout_landing_app{

        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .container_buttons_mobile_layout_landing_app > button{
        font-family: PoppinsRegular;
        font-weight: bold;
        color:white;
        font-size: 19px;
        height: 43px;
        margin-top: 15px;
        background: #633AF7;
        border-radius: 30px;
        border:none;
        outline:none;
    }

    .button_partner_mobile_layout_landing_app{

        display: flex;
        justify-content: center;
        align-items: center;

    }


    .button_partner_mobile_layout_landing_app > img{

        width: 49px;
        position: relative;
        left:-80px;
        top:-5px;

    }

    .name_button_partner_mobile_layout_landing_app{

        position: relative;
        top:5px;
        left:-19px;

    }



}