.container_jwt_token{

    display: flex;
    justify-content: center;
    
    align-items: center;
}

.container_jwt_token > img{

    width: 260px;
    margin-top: 140px;

}