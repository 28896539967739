@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}


.container_partners_mobile{

    display:flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    
}

.container_partners_mobile > h2{
    font-family: PoppinsRegular;

    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 135.2%;
    /* or 27px */

    text-align: center;

    color: #633AF7;


}

.card_partner_mobile{
    display: flex;
    padding-top:50px;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    width:40%;
    box-shadow:  0 0 5px rgba(0, 0, 0, 0.356);
    border-radius: 10px;

}

.card_partner_mobile > img{

    position: relative;
    top:-10px;
    
}

.card_partner_mobile > button{

    position: relative;
    top:-20px;
    border: none;
    outline: none;
    width: 90%;
    height: 31px;
    color:white;
    background: #633AF7;
    border-radius: 10px;
    margin-top: 30px;

}

.card_partner_mobile > img{

    object-fit: contain;
    width:140px;

}

.flex_partner_mobile{

    display: flex;
    justify-content: space-around;
    width: 98%;
    padding-bottom: 190px;
    
    flex-wrap: wrap;

}