
@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}


.partner_info_mobile{

    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    margin-top: -30px;
}

.container_partner_info_mobile{

    display: flex;

}


.icon_partner_info_mobile > img{

    width: 320px;
    

}




.flex_partner_info_mobile{

    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: -60px;
    margin-bottom: 70px;

}

.flex_partner_info_mobile > .icon_company_info_mobile{
    object-fit: contain;
    width: 50px;

}


.seta_icon_info_mobile{

    width: 55px;

}

.icon_container_compan_mobile{

    display: flex;
    flex-direction: column;
    width: 90%;


}

.flex_partner_info_mobile > h2{
    
    font-family: PoppinsRegular;
    position: relative;
    top:8px;
    font-size:0.9em;
    font-weight: bold;
}

.category_partner_info_mobile{

    display: flex;
    width:79%;
    
}

.category_partner_info_mobile > div{

    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    position: relative;
    top:-37px;
    width: 37%;
    height:39px;
    background: rgba(99, 58, 247, 0.3);
    border-radius: 30px;

}

.icon_description_company_info_mobile_2{

    display: flex;
    align-items: center;
    position: relative;
    top:-5px;
    margin-bottom: 8px;
    left:15px;

}




.icon_description_company_info_mobile_2 > p{


    position: relative;
    top:9px;
    font-size:0.9em;
}

.icon_description_company_info_mobile_2 > img{

    width: 30px;

}

.icon_description_company_info_mobile{

    display: flex;
    align-items: center;
    position: relative;
    left:1px;
    top:-5px;

}

.icon_description_company_info_mobile > p{
    position: relative;
    font-size:0.9em;
    top:9px;

}

.category_partner_info_mobile > div > h2{

    font-size: 1.1em;
    color:#633AF7;

}