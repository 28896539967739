@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}

@font-face{

    src:url(../../fonts/Comfortaa-Regular.ttf);
    font-family: Comfortaa-Regular;

}



.container_partners{

    display: flex;
    align-items: center;

}



.row_container{
    display: flex;
}


.info_user{

    position: relative;
    width:430px;
    height: 430px;
}

.info_user > img{

    width:430px;
    height: 430px;
}


.column3{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin:60px auto;
    width: 100%;
    position: relative;
    margin-left: 30px;
}



.address_partner{

    margin-top: 50px;

}

.data_user_primary_name{
    font-family: PoppinsRegular;
    font-weight: 800;
    font-size: 15px;
    text-shadow: 2px 2px 0px  transparent, -2px -2px 0px  transparent, 2px -2px 0px  #633AF7, -2px 2px 0px  transparent;
    position: absolute;
    top:230px;
    left:50px;
    z-index: 5;
    color: #FFFFFF;
    
}

.data_user_secondary_valid{
    font-family: PoppinsRegular;
    font-weight: 800;
    font-size: 15px;
    text-shadow: 2px 2px 0px  transparent, -2px -2px 0px  transparent, 2px -2px 0px  #633AF7, -2px 2px 0px  transparent;

    position: absolute;
    top:270px;
    left:50px;
    z-index: 5;
    color: #FFFFFF;

}

.data_user_validate{

    font-family: PoppinsRegular;
    font-weight: 800;
    font-size: 15px;
    text-shadow: 2px 2px 0px  transparent, -2px -2px 0px  transparent, 2px -2px 0px  #633AF7, -2px 2px 0px  transparent;

    position: absolute;
    top:270px;
    left:160px;
    z-index: 5;
    color: #FFFFFF;

}

.dashboard{

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 200px;
}


.data_user{

    padding-top: 15px;
    margin-top: -50px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 13px;
    width:311px;
    height: 311px;
}

.row_data_user{

    display: flex;
    align-items: center;
    justify-content: space-around;
}

.card_data_user{
    width: 158px;
    height: 91px;
    background: #633AF7;    
    border-radius: 16px;
}

.card_data_user_share{
    margin-top: 25px;
    width: 349px;
    height: 91px;
    background: #633AF7;    
    border-radius: 16px;
}

.card_data_user_share > h2{


    color:white;
    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding-top: 18px;
    padding-left: 18px;

}

.card_data_user > h2{

    color:white;
    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding-top: 18px;
    padding-left: 18px;
}

.textPrimary_data_user{

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    /* identical to box height, or 34px */
    
    margin-left: 15px;

    color: #633AF7;
}

.list_indications_dashboard{
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    top:90px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    width: 47%;
    margin-right: 30px;
    height: 602px;
}

.list_indications_dashboard > h2{
    font-weight: 600;
    font-size: 20px;
    margin-top: 20px;
    margin-left: 20px;
    font-family: PoppinsRegular;
    color: #633AF7;
    margin-bottom: 30px;
}


.test{
    font-family: Comfortaa-Regular;

    font-size: 17px;
    height: 70px;
    border-top: 0.5px solid rgb(206, 206, 206);
    border-bottom: 0.5px solid rgb(206, 206, 206);
}

.test2{
   
    position: relative;
    top:-10px;
    left:20px;

}

.test3{
   
    position: relative;
    top:10px;
    left:20px;

}

.icon_card_data_user{
    cursor: pointer;
    display: flex;
    align-items: center;

}

.icon_card_data_user > h2{

    font-family: PoppinsRegular;
    color:white;
    font-size: 15px;
    font-weight: bold;
    position: relative;
    top:-3px;

}

.icon_card_data_user > img {

    position: relative;
    top:-10px;
    width: 50px;

}

.icon_card_data_user_2{

    display: flex;
    align-items: center;

}

.icon_card_data_user_2 >   h2{
    font-family: PoppinsRegular;
    color:white;
    font-size: 15px;
    font-weight: bold;
    position: relative;
    top:-3px;
    left:15px;

}

.icon_card_data_user_2 > img{

    position: relative;
    top:-5px;
    left:15px;
    width: 40px;
}

.row_card_data_user_share{

    display: flex;
    align-items: center;
}

.dashboard_mobile {

    display: none;
}

.container_dashboard_mobile__desktop{

    display: none;

}

.card_expires{

    position: relative;
    
}

.active_plan_card_expires{
    display: flex;
    align-items: center;
    position: absolute;
    top:180px;
    left:130px;
    background: #633AF7;
    border-radius: 10px;
    width: 180px;
    cursor: pointer;
}

.active_plan_card_expires_mobile{

    display: flex;
    align-items: center;
    position: absolute;
    top:340px;
    left:150px;
    background: #633AF7;
    border-radius: 10px;
    width: 180px;
    cursor: pointer;
}

.active_plan_card_expires_mobile > img{

    width: 40px;


}

.active_plan_card_expires > img{

    width: 40px;

}

.active_plan_card_expires_mobile > h2{


    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    margin-top: 5px;
    color: #FFFFFF;

}

.active_plan_card_expires > h2{

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    margin-top: 5px;
    color: #FFFFFF;

}

.card_expires{

    cursor:pointer;

}



.card_expires_mobile{

    cursor: pointer;
    z-index: -5px;

}

@media screen and (max-width:421px){

    .container_partners{

        display: none;

    }

    .container_dashboard_mobile__desktop{

        display: block;
    
    }



}