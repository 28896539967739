
/* Fonts */


@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}

.button{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: PoppinsRegular;
    font-weight: bold;
    font-size: 19px;
    color:white;
    border:none;
    outline: none;
    width: 60%;
    height: 48px;
    background: #633AF7;
    border-radius: 30px;
    margin-bottom: 18px;
}