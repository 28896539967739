

@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}

.bottom_bar_mobile{
    position: fixed;
    bottom:0;
    width:100%;
    height: 100px;
    display: flex;
    justify-content: space-around;

}   

 

.flex_bottom_bar_mobile{
    position: fixed;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    bottom:0;

}

.flex_bottom_bar_mobile > div > img{

    width: 35px;
    object-fit: contain;

}

.flex_bottom_bar_mobile > div{

    z-index: 1;
    position: relative;
    top:10px;

}

.flex_bottom_bar_mobile > div > p{

    font-size: 0.7em;
    color:black;

}

.flex_bottom_bar_mobile > img{

    width: 500px;
    top:0px;

    position: absolute;
    z-index: -1;

}

.icon_parters_bottom_bar_mobile{

    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top:95px;

}

.icon_parters_bottom_bar_mobile > img{

    width: 35px;

}

.icon_parters_bottom_bar_mobile > h2{

    font-family: PoppinsRegular;
    color:black;
    font-size: 0.9em;
    margin-top: 2px;

}


.icon_dashboard_bottom_bar_mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -60px;
    background: #633AF7;
    width: 60px;
    height: 60px;
    border-radius: 100px;


}

.icon_dashboard_bottom_bar_mobile > img{

    width: 37px;

}

.icon_historic_bottom_bar_mobile{

    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top:95px;
}

.icon_historic_bottom_bar_mobile > img{

    width: 35px;

}

.icon_historic_bottom_bar_mobile > h2{
    font-family: PoppinsRegular;

    font-size: 0.9em;
    margin-top: 2px;

}