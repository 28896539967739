.sidebar{
    background: linear-gradient(180deg, rgba(255, 68, 248, 0.2) 0%, rgba(102, 49, 255, 0.2) 100%);
    box-shadow: 3.44444px 6.02778px 25.8333px rgba(0, 0, 0, 0.05);
    height: 100vh;
    width: 228px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    position: fixed;
    top:0;
    z-index: 8;
}


.icon_card_container{
    cursor: pointer;
    display: flex;
    justify-content: center;

}

.icon_card{
    width: 150px;
}

nav > ul li{

    list-style: none;
    margin-bottom: 15px;

}

li{

    width:100%;
    margin-left: 10px;
}

li:hover{

    cursor: pointer;

}

.checked::before{


    content: url(../../images/sidebarIcon.png);
    position: absolute;
    margin-bottom: 30px;    
    left:0;
}

.flex_icons{

    display: flex;
    align-items: center;
    margin-bottom: 15px;
}


.flex_icons > img{

    width: 31px;
    position: relative;
    top:-10px;
}

.exit{
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    bottom:0;
    height: 150px;
    width: 100%;
    margin-left: 10px;

}

.exit > p{

    position: relative;
    top:9px;

}

.exit > img{

    width: 50px;

}