@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}

@font-face{

    src:url(../../fonts/Comfortaa-Regular.ttf);
    font-family: Comfortaa-Regular;

}



.container_partners{

    display: flex;
    align-items: center;

}



.row_container{
    display: flex;
}


.info_user{

    position: relative;
    width:430px;
    height: 430px;
}

.info_user > img{

    width:430px;
    height: 430px;
}


.column_paymentPix{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin:70px auto;
    width: 100%;
}



.address_partner{

    margin-top: 50px;

}

.data_user_primary{
    font-family: PoppinsRegular;
    font-weight: 800;
    font-size: 15px;

    position: absolute;
    top:230px;
    left:50px;
    z-index: 5;
    color:black;
}

.data_user_secondary{
    font-family: PoppinsRegular;
    font-weight: 800;
    font-size: 15px;

    position: absolute;
    top:230px;
    left:220px;
    z-index: 5;
    color:black;

}

.dashboard{

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 198px;
}


.data_user{

    padding-top: 15px;
    margin-top: -50px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 13px;
    width:380px;
    height: 430px;
}

.row_data_user{

    display: flex;
    align-items: center;
    justify-content: space-around;
}

.card_data_user{
    width: 158px;
    height: 91px;
    background: #633AF7;    
    border-radius: 16px;
}

.card_data_user_share{
    margin-top: 25px;
    width: 349px;
    height: 91px;
    background: #633AF7;    
    border-radius: 16px;
}

.name_file{
    
    position: relative;
    top:30px;

}

.card_data_user_share > h2{


    color:white;
    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding-top: 18px;
    padding-left: 18px;

}

.card_data_user > h2{

    color:white;
    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding-top: 18px;
    padding-left: 18px;
}

.textPrimary_data_user{

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 160%;
    /* identical to box height, or 34px */
    
    margin-left: 15px;

    color: #633AF7;
}

.test{
    font-family: Comfortaa-Regular;

    font-size: 17px;
    height: 70px;
    border-top: 0.5px solid rgb(206, 206, 206);
    border-bottom: 0.5px solid rgb(206, 206, 206);
}

.test2{
   
    position: relative;
    top:-10px;
    left:20px;

}

.list_indications_pix{
    position: relative;
    top:90px;
    left:150px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    width: 36%;
    height: 190px;
}

.list_indications_pix > h2{
    font-weight: 600;
    font-size: 20px;
    margin-top: 20px;
    margin-left: 20px;
    font-family: PoppinsRegular;
    color: #633AF7;
    margin-bottom: 30px;
}


.test3{
   
    position: relative;
    top:10px;
    left:20px;

}

.icon_card_data_user{

    display: flex;
    align-items: center;

}

.icon_card_data_user > h2{

    font-family: PoppinsRegular;
    color:white;
    font-size: 15px;
    font-weight: bold;
    position: relative;
    top:-3px;

}

.icon_card_data_user > img {

    position: relative;
    top:-10px;
    width: 50px;

}

.icon_card_data_user_2{

    display: flex;
    align-items: center;

}

.icon_card_data_user_2 >   h2{
    font-family: PoppinsRegular;
    color:white;
    font-size: 15px;
    font-weight: bold;
    position: relative;
    top:-3px;
    left:15px;

}

.icon_card_data_user_2 > img{

    position: relative;
    top:-5px;
    left:15px;
    width: 40px;
}

.row_card_data_user_share{

    display: flex;
    align-items: center;
}

.row_card_data_user_share > div{

    margin-left:5px;

}



input[type=radio] {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
    transform: scale(0.4);
    margin-top: 15px;
    position: relative;
    left:-170px;
}

.checkbox{

    display: flex;
    justify-content: space-around;
    width: 60%;
}

.container_radio{

    display: flex;
    align-items: center;
    width:100%;
    height: 50px;
    padding-top: 5px;
    border-top: 0.5px solid rgb(206, 206, 206);
    border-bottom: 0.5px solid rgb(206, 206, 206);
}

.container_radio2{
    display: flex;

    align-items: center;
    width:100%;
    height: 50px;
    padding-top: 5px;
    border-bottom: 0.5px solid rgb(206, 206, 206);

}

.data_container_radio{

    display: flex;
}

.data_container_radio > label{
    font-family: Comfortaa-Regular;
    white-space: nowrap;
    margin-left:-340px;    
}

.selectMethodPayment{

    display: flex;
    justify-content: space-between;
    background: rgba(216, 219, 221, 0.4);
    backdrop-filter: blur(10px);
    /* Note: backdrop-filter has minimal browser support */
    width: 270px;
    margin:40px auto;
    border-radius: 60px;

}

.methodPayment{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    position: relative;
    top: 90px;
    left:50px;
    width: 35%;
    height: 740px;
    border-radius: 15px;
}

.creditCard{

    width: 175px;
    height: 71px;
    border-radius: 60px;
}

.creditCard_not_select{
    width: 175px;
    height: 71px;
    border-radius: 60px;
    display: flex;
    align-items: center;

}

.pix_select{

    width: 175px;
    height: 71px;
    background: #633AF7;
    border-radius: 60px;

}

.pix{
    width: 145px;
    height: 71px;
    border-radius: 60px;

}

.container_inputs_pix{

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.container_inputs_pix > .input > input{
    border:none;
    outline: none;
    width: 380px;
    height: 56px;
    background: #EAECEE;
    border-radius: 16px;
    cursor: pointer;

}

.container_inputs_pix > .input{

    display: flex;
    flex-direction: column;

}

.row_input{

    display:flex;
    width: 230px;
    justify-content: center;
    align-items: center;
}

.row_input > div{

    display: flex;
    flex-direction: column;

}

.input_copy{

    position: relative;
}

.copy_icon{

    position: absolute;
    right: 60px;
    top:170px;
    width: 70px;
}

.row_input > div > input{

    border:none;
    outline: none;
    width: 150px;
    height: 56px;
    margin-right: 40px;
    background: #EAECEE;
    border-radius: 16px;

}

.creditCard{

    display: flex;
    align-items: center;

}

.creditCard_not_select > p{
    font-family: PoppinsRegular;
    margin-top: 19px;
    margin-left: 15px;
    color:#633AF7;
    font-size: 18px;

}

.creditCard_not_select > img{
    margin-left: 10px;
    width: 35px;
}

.pix_select{

    display: flex;
    justify-content: center;
    align-items: center;
}

.pix_select > img{

    width: 55px;
    
}

.clean{

    height: 30px;

}

input[type="file"]{

    display: none;

}

.messageErrorComprovante{

    color:red;

}