
@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}



.container_sucess_send_mail_mobile{

    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.container_sucess_send_mail_mobile > h2{

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;

    color: #633AF7;

}

.container_sucess_send_mail_mobile > p{

        font-family: PoppinsRegular;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        margin-top: 5px;
        /* or 154% */
        
        text-align: center;

        color: #000000;

}

.container_image_sucess_send_mail_mobile > img{

    width: 300px;
    margin-top: -40px;
}

.flex_container_image_sucess_send_mail_mobile{
    margin-top: -30px;
    display: flex;
    justify-content: space-around;

}


.button_sucess_send_mail_mobile{
    display: flex;
    justify-content: center;
    width: 100%;
}

.button_sucess_send_mail_mobile > button{

    width: 70%;

}

.not_receive{

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    
    color: rgba(60, 60, 67, 0.72);
    
}

.send{
    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: center;

    color: #633AF7;


}