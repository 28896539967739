@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}

@font-face{

    src:url(../../fonts/Comfortaa-Regular.ttf);
    font-family: Comfortaa-Regular;

}



.container_partners{

    display: flex;
    align-items: center;

}



.row_container{
    display: flex;
}


.info_user{

    position: relative;
    width:430px;
    height: 430px;
}

.info_user > img{

    width:430px;
    height: 430px;
}


.column_paymentCard{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin:70px auto;
    width: 100%;
}



.address_partner{

    margin-top: 50px;

}

.data_user_primary{
    font-family: PoppinsRegular;
    font-weight: 800;
    font-size: 15px;

    position: absolute;
    top:230px;
    left:50px;
    z-index: 5;
    color:black;
}

.data_user_secondary{
    font-family: PoppinsRegular;
    font-weight: 800;
    font-size: 15px;

    position: absolute;
    top:230px;
    left:220px;
    z-index: 5;
    color:black;

}

.dashboard{

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 198px;
}


.data_user{

    padding-top: 15px;
    margin-top: -50px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 13px;
    width:380px;
    height: 430px;
}

.row_data_user{

    display: flex;
    align-items: center;
    justify-content: space-around;
}

.card_data_user{
    width: 158px;
    height: 91px;
    background: #633AF7;    
    border-radius: 16px;
}

.card_data_user_share{
    margin-top: 25px;
    width: 349px;
    height: 91px;
    background: #633AF7;    
    border-radius: 16px;
}

.card_data_user_share > h2{


    color:white;
    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding-top: 18px;
    padding-left: 18px;

}

.card_data_user > h2{

    color:white;
    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding-top: 18px;
    padding-left: 18px;
}

.textPrimary_data_user{

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 160%;
    /* identical to box height, or 34px */
    
    margin-left: 15px;

    color: #633AF7;
}

.test{
    font-family: Comfortaa-Regular;

    font-size: 17px;
    height: 70px;
    border-top: 0.5px solid rgb(206, 206, 206);
    border-bottom: 0.5px solid rgb(206, 206, 206);
}

.test2{
   
    position: relative;
    top:-10px;
    left:20px;

}

.list_indications{
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    top:90px;
    left:30px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    width: 37%;
    height: 547px;
}

.list_indications > h2{
    font-weight: 600;
    font-size: 20px;
    margin-top: 20px;
    margin-left: 20px;
    font-family: PoppinsRegular;
    color: #633AF7;
    margin-bottom: 30px;
}



.list_indications_profile{

    position: relative;
    top:90px;
    left:160px;
    margin-left: 30px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    width: 36%;
}

.list_indications_card{
    position: relative;
    top:90px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    width: 36%;
    height: 190px;
}

.list_indications_card > h2{
    font-weight: 600;
    font-size: 20px;
    margin-top: 20px;
    margin-left: 20px;
    font-family: PoppinsRegular;
    color: #633AF7;
    margin-bottom: 30px;
}


.test3{
   
    position: relative;
    top:10px;
    left:20px;

}

.icon_card_data_user{

    display: flex;
    align-items: center;

}

.icon_card_data_user > h2{

    font-family: PoppinsRegular;
    color:white;
    font-size: 15px;
    font-weight: bold;
    position: relative;
    top:-3px;

}

.icon_card_data_user > img {

    position: relative;
    top:-10px;
    width: 50px;

}

.icon_card_data_user_2{

    display: flex;
    align-items: center;

}

.icon_card_data_user_2 >   h2{
    font-family: PoppinsRegular;
    color:white;
    font-size: 15px;
    font-weight: bold;
    position: relative;
    top:-3px;
    left:15px;

}

.icon_card_data_user_2 > img{

    position: relative;
    top:-5px;
    left:15px;
    width: 40px;
}

.row_card_data_user_share{

    display: flex;
    align-items: center;
}

.row_card_data_user_share > div{

    margin-left:5px;

}

.error_desktop_profile{

    color:red;
    position: relative;
    top:-35px;
    left:15px;

}


input[type=radio] {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
    transform: scale(0.4);
    margin-top: 15px;
    position: relative;
    left:-170px;
}

.checkbox{

    display: flex;
    justify-content: space-around;
    width: 60%;
}

.container_radio{

    display: flex;
    align-items: center;
    width:100%;
    height: 50px;
    padding-top: 5px;
    border-top: 0.5px solid rgb(206, 206, 206);
    border-bottom: 0.5px solid rgb(206, 206, 206);
}

.container_radio2{
    display: flex;

    align-items: center;
    width:100%;
    height: 50px;
    padding-top: 5px;
    border-bottom: 0.5px solid rgb(206, 206, 206);

}

.data_container_radio{

    display: flex;
}

.data_container_radio > label{
    font-family: Comfortaa-Regular;
    white-space: nowrap;
    margin-left:-340px;    
}

.selectMethodPayment{

    display: flex;
    justify-content: space-between;
    background: rgba(216, 219, 221, 0.4);
    backdrop-filter: blur(10px);
    /* Note: backdrop-filter has minimal browser support */
    width: 250px;
    margin:40px auto;
    border-radius: 60px;

}

.methodPayment_profile{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    position: relative;
    top: 90px;
    left:50px;
    width: 35%;
    height: 340px;
    border-radius: 15px;
}

.creditCard{

    width: 175px;
    height: 71px;
    background: #633AF7;
    border-radius: 60px;
}

.pix{
    width: 125px;
    height: 71px;
    border-radius: 60px;

}

.container_inputs_payment{

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.container_inputs_payment > .input > input{
    border:none;
    outline: none;
    width: 380px;
    height: 56px;
    background: #EAECEE;
    border-radius: 16px;

}

.select_desktop_mobile{

     border:none;
    outline: none;
    width: 250px;
    height: 56px;
    background: #EAECEE;
    border-radius: 16px;

}

.container_inputs_payment > .input{

    display: flex;
    flex-direction: column;

}

.row_input{

    display:flex;
    width: 230px;
    justify-content: center;
    align-items: center;
}

.row_input > div{

    display: flex;
    flex-direction: column;

}

.row_input > div > input{

    border:none;
    outline: none;
    width: 150px;
    height: 56px;
    margin-right: 40px;
    background: #EAECEE;
    border-radius: 16px;

}

.creditCard{

    display: flex;
    align-items: center;

}

.creditCard > p{
    font-family: PoppinsRegular;
    margin-top: 19px;
    margin-left: 15px;
    color:white;
    font-size: 18px;

}

.creditCard > img{
    margin-left: 5px;
    width: 35px;
}

.pix{

    display: flex;
    justify-content: center;
    align-items: center;
}

.pix > img{

    width: 55px;

}

.clean{

    height: 30px;

}

.errorMessage_card{

    color:red;

}

.errorPayment{

    text-align: center;
    color:red;
    
}

.icon_indications_profile{

    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.icon_indications_profile > img{
    
    width: 120px;
    border-radius: 100px;

}


.container_inputs_indications_profile{
    font-family: PoppinsRegular;

    display: flex;
    margin-top: 50px;
    justify-content: space-around;
    flex-wrap: wrap;

}

.checked_payment_profile > p{

    margin-top: 15px;
    margin-left: 3px;

}

.container_select_option_payment_profile{

    display: flex;
    justify-content: center;

}

.select_option_payment_profile{
    display: flex;
    align-items: center;
    width: 337px;
    height:63px;
    background: rgba(216, 219, 221, 0.4);
    backdrop-filter: blur(10px);
    border-radius: 60px;
}

.checked_payment_profile{
    cursor: pointer;
    display: flex;
    align-items: center;
    color:white;
    width: 177px;
    height: 63px;
    background: #633AF7;
    border-radius: 60px;

}

.checked_payment_profile_not_background{
    cursor: pointer;
    display: flex;
    align-items: center;
    color:white;
    width: 177px;
    height: 63px;
    background: none;
    border-radius: 60px;
}

.checked_payment_profile_not_background > p{

    margin-top: 15px;
    color:#633AF7;
}

.checked_payment_profile_ted_not_background{

    cursor: pointer;
    display: flex;
    align-items: center;
    color:white;
    width: 177px;
    height: 63px;
    background: none;
    border-radius: 60px;
}

.checked_payment_profile_ted_not_background > img{

    width: 50px;
    margin-left: 15px;

}

.checked_payment_profile_ted_not_background > p{
    margin-top: 15px;
    color:#633AF7;

}

.checked_payment_profile > img{

    width: 50px;
    margin-left: 15px;

}

.container_inputs_indications_profile > h2{
    position: relative;
    left:-50px;
    font-size: 18.5px;
    color: rgba(0, 0, 0, 0.68);
    margin-bottom: 30px;
}

.container_inputs_indications_profile > div{

    display: flex;
    flex-direction: column;
    width: 240px;
}

.container_inputs_indications_profile > div > input{

    width: 90%;
    height: 50px;
    text-indent: 24px;
    margin-left: 10px;
    font-size:1.0em;
}



.container_inputs_indications_profile > div > label{

    position: relative;
    top:-100px;
    left:20px;
    color:black;
    font-family: Comfortaa-Regular;
    font-style: normal;
    font-size: 15px;

}

.input_indications_profile{

    position: relative;
}


.input_indications_profile_select{

    width: 90%;
    height: 46px;
    margin-left: 10px;
}

.input_icon_indications_pix{

    position: absolute;
    width: 40px;
    margin-top: 5px;
    margin-left:25px;
}

.input_icon_indications{

    position: absolute;
    width: 30px;
    margin-top: 12px;
    margin-left:15px;
}

.label_profile{

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    display: flex;
    align-items: center;

    color: #633AF7;
    margin-left: 15px;
    margin-bottom: 15px;

}


.btn_indications_input{

    display: flex;
    width: 100%;
    position: relative;
}

.btn_indications_input > button{

    margin:68px auto;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: PoppinsRegular;
    font-weight: 'bold';
    font-size: 14px;
    color:white;
    border:none;
    outline: none;
    width: 35%;
    height: 48px;
    background: #633AF7;
    border-radius: 30px;
    margin-bottom: 18px;
}

.image_user_profile{
    
    position: relative;
}

.camera_icon{

    position: absolute;

}

.profile_mobile{

    display: none;

}

/* Css Mobile */



.input_profile_mobile_select_2{

    font-family: PoppinsRegular;
    border:none;
    width: 100%;
    background: #eff1f1;
    height: 50px;
    box-sizing: border-box;
    border-radius: 70px;
    padding-left: 15px;
    font-size: 1.0em;

}


::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    black;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    black;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    black;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    black;
}

@media screen and (max-width:421px){

    .container_partners{

        display: none;

    }

.profile_mobile{

    display: block;

}





}
