

@font-face{

    src:url(../../fonts/Poppins-Bold.ttf);
    font-family: PopinsBold;

}

.container_not_found_page{
    overflow-y: hidden;
    display: flex;
    overflow-x: hidden;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding-top: 120px;
    flex-direction: column;
    font-family: PopinsBold;

}

.container_not_found_page > h1{
    
    margin-bottom: 10px;

}

.container_not_found_page > h2{

    margin-bottom: 30px;

}

.return_icon{
    
    cursor: pointer;

}

@media screen and (max-width:720px){

    .icon_not_found{

        width: 90%;
        background-color: red;

    }


}