

@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}
.row_historic_transactions_mobile_component{

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:25px;
}

.row_historic_transactions_mobile_component > .data_historic_transactions_mobile_component > p{

    font-size: 0.8em;
    height: 3px;
    margin-left: 9px;
    font-family: PoppinsRegular;

}

.historic_transactions_mobile_component > div > img{

    width: 35px;

}


.data_historic_transactions_mobile_component{

    display: flex;
    flex-direction: column;

}

.value_historic_transactions_mobile_component > p{

    position: relative;
    top:5px;
    margin-left: 120px;
    

}