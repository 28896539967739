
@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}

.dashboard_mobile{

    display:flex;
    flex-direction: column;
    align-items: center;

}

.dashboard_mobile > h2{

    font-style: normal;
    font-weight: 900;
    font-size: 1.5em;
    margin-top:20px;
    line-height: 135.2%;
    /* or 27px */

    text-align: center;

    color: #633AF7;

    font-family: PoppinsRegular;

}

.icon_card_dashboard_mobile{
    
    height: 250px;
    
}

.icon_card_dashboard_mobile > img{
    top:-50px;
    width: 388px;
    position: relative;

}

.icon_card_dashboard_mobile > .name_dashboard_mobile{

    position: absolute;
    top:360px;
    left:60px;
    font-size:0.9em;
    color:white;
    text-shadow: 2px 2px 0px  #633AF7, -2px -2px 0px  transparent, 2px -2px 0px  transparent, -2px 2px 0px  transparent;

}


.icon_card_dashboard_mobile > .validate_dashboard_mobile{
    color:white;
    text-shadow: 2px 2px 0px  #633AF7, -2px -2px 0px  transparent, 2px -2px 0px  transparent, -2px 2px 0px  transparent;
    position: absolute;
    top:385px;
    left:60px;
    font-size:0.9em;
}


.container_dashboards_dashboard_mobile{
    
    width: 80%;
    height: 190px;
    
}

.dashboard_mobile > img{

    z-index: 10;

}