

@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}
.icon_login_mobile{

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.icon_login_mobile > img{

    width: 190px;

}

.login_mobile{

    display: flex;
    flex-direction: column;
    align-items: center;

}

.login_mobile > h2{

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 1.5em;
    line-height: 32px;

    color: #633AF7;

}

.container_inputs_login_mobile{

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    
}

.container_inputs_login_mobile > input{

    font-size: 1.0em;
    height: 50px;
    width: 90%;
    text-indent: 0;
}

.row_container_inputs_login_mobile{

    width: 90%;
    display: flex;
    justify-content: space-between;

}

.row_container_inputs_login_mobile > p{

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    text-align: center;

    color: #633AF7;
}

.message_error_login_mobile{

    color:red;
    position: relative;
    top:-5px;
    left:-80px;

}

.login_invalid_login_mobile{

    color:red;
    font-size:0.9em;
    text-align:center;

}

.footer_mobile{

    margin-top:48px;
}

.footer_mobile > h2{

    font-family: PoppinsRegular;
    font-size:1.2em;
}

.facebook_button_mobile{

    margin-top: 40px;

}

.arrow_container_mobile{
    width: 90%;
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.arrow_container_mobile > p{
    
    font-size: 0.9em;
    position: relative;
    top:8px;
    font-weight: 500;

}

.facebook_button_mobile{
    width:290px;
    margin-top: 30px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 70px;
    height: 50px;

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    /* identical to box height */

    text-align: center;

    color: #000000;


}



.container_button_facebook_mobile{
    position: relative;
    display: flex;
    align-items: center;
}
.container_button_facebook_mobile > img{

    position: absolute;
    left:30px;
    top:40px;
    width: 30px;

}