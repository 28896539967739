@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}


.container_forgot_password_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.text_primary_forgot_password{

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 1.3em;
    line-height: 20px;
    /* or 100% */
    
    text-align: center;
    
    color: #633AF7;
}

.container_forgot_password_mobile > p{

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 1.0em;
    line-height: 20px;
    /* or 133% */
    margin-top: 15px;
    text-align: center;

    color: #000000;

}

.container_image_forgot_password_mobile > img{
    object-fit: contain;
    width: 100%;
    margin-top: -75px;
}

.container_input_forgot_password_mobile{

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
    width: 100%;
    
}

.row_forgot_password_mobile{
    width: 76%;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;

}

.container_button_forgot_password_mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

}


.container_button_forgot_password_mobile > button{

    margin-bottom: 25px;

}

.remember_forgot_password_mobile{

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: rgba(60, 60, 67, 0.72);
}

.login_forgot_password_mobile{


    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: center;

    color: #633AF7;

}

.message_error_forgot_password_mobile{

    color:red;
    position: absolute;
    left:50px;
    top:56px;
    
}

.input_forgot_password_mobile{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

}