select{
    background: none;
    font-family: PoppinsRegular;
    width: 56%;
    height: 40px;
    border:none;
    outline: none;
    font-size: 1.0em;
    border: 1px solid rgba(0, 0, 0, 0.51);
    text-indent: 15px;
    border-radius: 70px;
    color:black;
    margin-bottom: 30px;
}
