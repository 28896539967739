
@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}

.checkbox_mobile{
    position: relative;
    appearance: none;
    transform: scale(0.7);
    width: 15px;
    height: 15px;
    border-radius: 100%;
    border: 2px solid #633AF7;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox_mobile:before{
    content: '';
    position: absolute;
    width:35px;
    border-radius: 100%;
    height: 35px;
    background-color:#633AF7;
    opacity: 0;
    transition: all .3s;
}

.checkbox_mobile:checked::before{

    opacity: 1;
    
}

.container_checkbox_mobile{
    margin-top: 15px;
    display: flex;
    width: 87%;
    align-items: center;
}

.container_checkbox_mobile > label{

    position: relative;
    top: -9px;
    left:5px;
    font-family: PoppinsRegular;
    font-weight: bold;
    font-size:0.9em;
}