.icon_mail_inputs_login{

    width: 320px;
    margin:0 auto;
}
.row_inputs_login{

    display: flex;
    align-items: center;

}

.row_inputs_login > a {

    margin-top: -15px;
    font-size:1.2em;
    
    cursor: pointer;
}