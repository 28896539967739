.header_dashboard_mobile{

    display: flex;
    justify-content: space-around;
    align-items: center;

    background: linear-gradient(135.99deg, rgba(102, 49, 255, 0.3) 16.14%, rgba(255, 68, 248, 0.4) 75.43%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 158px;

    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;

}

.back_icon_header_dashboard_mobile{

    width: 40px;

}

.user_icon_header_dashboard_mobile{

    width: 40px;

}

.icon_header_dashboard_mobile2{

    width: 160px;

}

.user_icon_header_dashboard_mobile{

    border-radius: 100px;

}