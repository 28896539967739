@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}
html,body{

    height: 100%;

}



.payment_card_mobile{

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;

}

.select_type_payment_card_mobile{
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: rgba(216, 219, 221, 0.4);
    backdrop-filter: blur(10px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 60px;
    width: 80%;
    height: 60px;
}

.card_option_payment_pix_mobile{

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 60px;
    height:60px;
    width: 150px;

}

.card_option_payment_pix_mobile > img{

    object-fit: contain;
    width:30px;

}

.pix_option_payment_pix_mobile{

    display: flex;
    align-items: center;
    justify-content: center;
    background: #633AF7;
    border-radius: 60px;
    height:60px;
    width: 150px;

}

.pix_option_payment_pix_mobile > img{

    object-fit: contain;
    width:70px;

}

.card_option_payment_pix_mobile > p{

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 1.1em;
    line-height: 27px;
    /* identical to box height */
    
    
    color: #633AF7;
    position: relative;
    top:9px;
    left:5px;

}

.input_container_payment_card_mobile{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 35px;

}

.input_container_payment_card_mobile > div >  input{
    position: relative;
    width: 100%;
    background: #EAECEE;
    outline:none;
    border:none;
    
}

.input_container_payment_card_mobile > div > img{

    position: absolute;
    right: 50px;
    top:310px;
    width: 45px;

}

.input_container_payment_card_mobile_select > select {

    width: 100%;
    background: #EAECEE;
    outline:none;
    border:none;
    height: 50px;
    margin-bottom: 15px;


}

.flex_card_option_mobile{

    display: flex;
    justify-content: space-around;

}

.flex_card_option_mobile > div > input{

    background: #EAECEE;
    outline:none;
    border:none;
    width: 90%;
    height: 50px;
    color: #424D59;

}


.input_container_payment_card_mobile > div > label{

    position: relative;
    left:8px;

}

.flex_card_option_mobile > div > label{

    position: relative;
    left:8px;


}

.payment_card_mobile_button > button{

    width: 100%;
    height: 63px;

}

.upload_payment_card_mobile{
    object-fit: contain;
    width: 280px;

    
}

.payment_card_mobile > #file{

    display: none;


}

.container_upload_payment_card_mobile{
    display:flex;
    justify-content: center;
    margin-left: -20px;

}

.container_comprovante_payment_card_mobile{

    display: flex;
    justify-content: center;
    padding-top: 30px;
    margin-bottom: 30px;

}