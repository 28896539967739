
@font-face {
    font-family: Poppins-Regular;
    src: url(../../fonts/Poppins-Regular.ttf);
}


.card{
    
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    width: 325px;
    margin-bottom: 50px;
    margin-left: 50px;
    height: 690px;
}

a{

    color:black;
    text-decoration: none;

}

.carousel-item{

    height: 320px;

}

.icon_partner{

    width: 320px;

}

.card_icon_partner::after{

    content: '';
    display: block;
    width: 250px;
    margin:-5px auto;
    border: 0.8px solid #C1C1C1;

}

.card_data{

    display: flex;
    align-items: center;

}


.card_data > img{
    object-fit: contain;
    width: 100px;
    height: 100px;
    margin-left: 10px;
    margin-right: 10px;
}

.card_data > h2{
    font-family: Poppins-Regular;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 500;

    color: #000000;
}

.line{

    border: 0.8px solid #C1C1C1;
    height: 60px;
}

.info_partner > p{
    font-weight: normal;
    font-family: Poppins-Regular;
    font-size: 13px;

}

.map{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.icon_partern{

    display: flex;
    align-items: center;
    width: 238px;
    font-size: 15px;
    margin-bottom: 15px;
    text-align: left;
}





.icon_partern > img{

    width: 30px;

}

.icon_partern > p{
    
    position: relative;
    top:10px;
    left:10px;
    font-size: 13px;
}
