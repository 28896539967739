
.header_mobile{

    display: none;

}

@media screen and (max-width:720px){


    .header_mobile{

        display: flex;
        justify-content: center;
    }
    
    .icon_header_mobile > .icon_company_mobile {
        width: 170px
    }
    
    .back_icon_mobile{
        position: relative;
        top: -15px;
        left:-55px;
        width: 40px;
    
    }

}
