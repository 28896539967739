
@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}

.plans_mobile{

    display:flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    padding:20px;
    height: 90px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-top: 20px;

}


.column1_plans_mobile > h2{

    font-size: 1.0em;
    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 160% */
    
    
    color: #633AF7;
    
}


.column2_plans_mobile > h2{

    font-size: 1.0em;
    position: relative;
    top:11px;

    font-size: 1.0em;
    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 160% */
    
    
    color: black;

}

.column2_plans_mobile > img{

    width: 50px;

}