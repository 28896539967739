@font-face{

    src:url(../../fonts/Poppins-Regular.ttf);
    font-family: PoppinsRegular;

}
html,body{

    height: 100%;

}



.payment_card_mobile{

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;

}

.select_type_payment_card_mobile{
    display: flex;
    align-items: center;
    background: rgba(216, 219, 221, 0.4);
    backdrop-filter: blur(10px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 60px;
    width: 80%;
    height: 60px;
}

.card_option_payment_card_mobile{

    display: flex;
    align-items: center;
    justify-content: center;
    background: #633AF7;
    border-radius: 60px;
    height:60px;
    width: 150px;

}

.card_option_payment_card_mobile > img{

    object-fit: contain;
    width:30px;

}

.pix_option_payment_card_mobile{

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 60px;
    height:60px;
    width: 150px;

}

.pix_option_payment_card_mobile > img{

    object-fit: contain;
    width:70px;

}

.card_option_payment_card_mobile > p{

    font-family: PoppinsRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 1.1em;
    line-height: 27px;
    /* identical to box height */
    
    
    color: #FFFFFF;
    position: relative;
    top:9px;
    left:5px;
    color:white;

}

.input_container_payment_card_mobile{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 35px;

}

.input_container_payment_card_mobile > div >  input{
    width: 100%;
    background: #EAECEE;
    outline:none;
    border:none;
    
}

.input_container_payment_card_mobile_select > select {

    width: 100%;
    background: #EAECEE;
    outline:none;
    border:none;
    height: 50px;
    margin-bottom: 15px;


}

.flex_card_option_mobile{

    display: flex;
    justify-content: space-around;

}

.flex_card_option_mobile > div > input{

    background: #EAECEE;
    outline:none;
    border:none;
    width: 90%;
    height: 50px;
    color: #424D59;

}


.input_container_payment_card_mobile > div > label{

    position: relative;
    left:8px;

}

.flex_card_option_mobile > div > label{

    position: relative;
    left:8px;


}

.payment_card_mobile_button > button{

    width: 100%;
    height: 63px;

}

.error_payment_mobile{

    position: relative;
    left:13px;
    top:-5px;
    color:red;
    font-size:0.9em;

}